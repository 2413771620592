
  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;

  }

  .list {
    display: flex;
    flex-flow: row wrap;
  }

  .item {
    width: 30%;
    min-width: 529px;
    height: 168px;
    margin: 0 32px 20px 0;
    padding: 15px 25px 14px 15px;
    border-radius: 10px;
    box-shadow: 0 2px 6px rgba(186, 190, 196, 0.20);
    background-image: url('../../assets/img/plate_bg.png'), linear-gradient(to bottom, #EBF2FE, #FDFEFF);
    background-size: 140px auto, 100% 100%;
    background-repeat: no-repeat;
    background-position: top;
    cursor: pointer;

    .top {

      img {
        width: 35px;
        height: 35px;
        margin-right: 10px;
      }

      .title {
        flex: 1;

        p {
          font-family: Source Han Sans CN, Source Han Sans CN;
          font-weight: 500;
          font-size: 16px;
          color: #000000;
          margin-bottom: 0;
        }

        .more {
          font-family: Source Han Sans CN, Source Han Sans CN;
          font-weight: 400;
          font-size: 16px;
          color: #555770;

          .dot {
            width: 8px;
            height: 8px;
            border-radius: 8px;
          }

          .text {
            margin: 0 8px 0 10px;
          }

          .anticon {
            font-size: 14px;
          }
        }
      }
    }

    .detail {
      align-items: flex-start;
      padding: 10px 0 0 45px;

      div {
        p {
          margin-bottom: 10px;
          font-family: Source Han Sans CN, Source Han Sans CN;
          font-weight: 400;
          font-size: 14px;
          color: #555770;

          span {
            color: #EA1818;
          }
        }

        &:nth-child(2) {
          p {
            text-align: right;
          }
        }
      }
    }
  }

@vab-color-blue: #1890ff;@vab-margin: 20px;@vab-padding: 30px;@vab-header-height: 65px;