.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.list {
  display: flex;
  flex-flow: row wrap;
}
.item {
  width: 30%;
  min-width: 529px;
  height: 168px;
  margin: 0 32px 20px 0;
  padding: 15px 25px 14px 15px;
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(186, 190, 196, 0.2);
  background-image: url('../../assets/img/plate_bg.png'), linear-gradient(to bottom, #EBF2FE, #FDFEFF);
  background-size: 140px auto, 100% 100%;
  background-repeat: no-repeat;
  background-position: top;
  cursor: pointer;
}
.item .top img {
  width: 35px;
  height: 35px;
  margin-right: 10px;
}
.item .top .title {
  flex: 1;
}
.item .top .title p {
  font-family: Source Han Sans CN, Source Han Sans CN;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
  margin-bottom: 0;
}
.item .top .title .more {
  font-family: Source Han Sans CN, Source Han Sans CN;
  font-weight: 400;
  font-size: 16px;
  color: #555770;
}
.item .top .title .more .dot {
  width: 8px;
  height: 8px;
  border-radius: 8px;
}
.item .top .title .more .text {
  margin: 0 8px 0 10px;
}
.item .top .title .more .anticon {
  font-size: 14px;
}
.item .detail {
  align-items: flex-start;
  padding: 10px 0 0 45px;
}
.item .detail div p {
  margin-bottom: 10px;
  font-family: Source Han Sans CN, Source Han Sans CN;
  font-weight: 400;
  font-size: 14px;
  color: #555770;
}
.item .detail div p span {
  color: #EA1818;
}
.item .detail div:nth-child(2) p {
  text-align: right;
}
