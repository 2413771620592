.vab-tabs {
  padding: 0 20px;
  background: #ffffff;
}
.vab-tabs-left-panel {
  float: left;
  width: calc(100% - 52px - 20px - 20px);
}
.vab-tabs-right-panel {
  float: left;
  width: 52px;
}
.vab-tabs .ant-tabs-bar {
  margin: 0 !important;
}
.vab-tabs .ant-tabs-tab {
  height: 32px !important;
  margin-right: 5px !important;
  line-height: 32px !important;
  background: #ffffff !important;
  border: 1px solid #dedede !important;
}
.vab-tabs .ant-tabs-tab-prev,
.vab-tabs .ant-tabs-tab-next {
  height: 32px !important;
  line-height: 32px !important;
}
.vab-tabs .ant-tabs-tab-active {
  border: 1px solid #1890ff !important;
}
.vab-tabs .ant-tabs-tab-active .ant-tabs-close-x {
  color: #1890ff !important;
}
