
  .vab-tabs {
    padding: 0 @vab-margin;
    background: #ffffff;
    &-left-panel {
      float: left;
      width: calc(100% - 52px - @vab-margin - @vab-margin);
    }
    &-right-panel {
      float: left;
      width: 52px;
    }
    .ant-tabs {
      &-bar {
        margin: 0 !important;
      }
      &-tab {
        height: 32px !important;
        margin-right: 5px !important;
        line-height: 32px !important;
        background: #ffffff !important;
        border: 1px solid #dedede !important;
      }
      &-tab-prev,
      &-tab-next {
        height: 32px !important;
        line-height: 32px !important;
      }
      &-tab-active {
        border: 1px solid #1890ff !important;
        .ant-tabs-close-x {
          color: #1890ff !important;
        }
      }
    }
  }

@vab-color-blue: #1890ff;@vab-margin: 20px;@vab-padding: 30px;@vab-header-height: 65px;